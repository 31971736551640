import React from 'react'

const Title = ({ color = 'primary', title }) => {
  return (
    <span className={`text-xl font-medium border-b-4 border-secondary text-${color}`} style={{ width: 'fit-content' }}>
      {title}
    </span>
  )
}

export default Title
