// noinspection HtmlUnknownAnchorTarget,XmlDeprecatedElement

import * as React from 'react'
import { graphql } from 'gatsby'
import SwiperCore, { Autoplay, FreeMode, Pagination } from 'swiper'
import Layout from '../components/layout/Layout'
import Title from '../components/shared/Title'
import { Swiper, SwiperSlide } from 'swiper/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans, Translation } from 'gatsby-plugin-react-i18next'

const tex = {
  n: {
    1: () => (
      <Trans>
        I nostri tessuti sono tutti tinti in filo e nascono da un'attenta analisi delle migliori materie prime che
        garantiscono risultati eccellenti.
      </Trans>
    ),
    2: () => (
      <Trans>
        Il processo produttivo è seguito e verificato per garantire un'ottima qualità. La preparazione avviene tramite
        roccatrici ed orditoi interni all'azienda.
      </Trans>
    ),
    3: () => (
      <Trans>
        Abbiamo diversi telai moderni (jacquard e licci) che permettono di produrre sia metraggi minimi che grandi
        quantità, mantenendo qualità eccellente.
      </Trans>
    ),
    4: () => (
      <Trans>
        Controllo qualità e spedizioni.
      </Trans>
    )
  },
  xs: {
    1: () => (
      <Trans>
        Tessuti tinti in filo che nascono dalle migliori materie prime.
      </Trans>
    ),
    2: () => (
      <Trans>Processi produttivi verificati.</Trans>
    ),
    3: () => (
      <Trans>
        Telai moderni (jacquard e licci) per metraggi minimi e grandi quantità.
      </Trans>
    ),
    4: () => (
      <Trans>
        Controllo qualità e spedizioni.
      </Trans>
    )
  }
}

const IndexPage = ({ data }) => {
  SwiperCore.use([Autoplay])
  const {
    imageCover,
    iconPen,
    iconLeaf,
    iconHand,
    iconGear,
    imageAbout1,
    imageAbout2,
    imagesTessuti,
    imgProduzione1,
    imgProduzione2,
    imgProduzione3,
    imgProduzione4
  } = data
  return (
    <Layout>
      <div>
        <div
          className="bg-left xl:bg-bottom bg-cover bg-no-repeat text-white xs:py-16 sm:py-20 lg:py-28 2xl:py-48 px-8 md:px-16 2xl:px-48"
          style={{ backgroundImage: `url("${imageCover.childImageSharp.gatsbyImageData.images.fallback.src}")` }}>
          <div className="2xl:max-w-screen-2xl mx-auto">
            <div className="mb-4">
              <Translation>
                {t => (
                  <Title title={t('Dal 1963')} color="white"/>
                )}
              </Translation>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold mb-4">
              <Trans>
                Tessuti<br/>
                Jacquard.
              </Trans>
            </h1>
            <div className="flex items-center mb-2">
              <span className="text-2xl font-medium mr-3"><Trans>100% Made in Italy</Trans></span>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="43.926" height="21.654" viewBox="0 0 53.926 31.654">
                  <g id="Group_19" data-name="Group 19" transform="translate(0 0.005)">
                    <rect id="Rectangle_20" data-name="Rectangle 20" width="17.975" height="31.654"
                          transform="translate(0 -0.005)" fill="#009246"/>
                    <rect id="Rectangle_21" data-name="Rectangle 21" width="17.975" height="31.654"
                          transform="translate(17.975 -0.005)" fill="#fff"/>
                    <rect id="Rectangle_22" data-name="Rectangle 22" width="17.975" height="31.654"
                          transform="translate(35.951 -0.005)" fill="#ce2b37"/>
                  </g>
                </svg>
              </div>
            </div>
            <p className="text-lg mb-4 max-w-sm">
              <Trans>La <strong>tessitura Fumagalli Carlo & Figli</strong> è un'azienda nata dalla passione e
                dall'esperienza
                tessile tramandata di generazione in generazione.</Trans>
            </p>
            <div>
              <a href="#contatti">
                <button className="bg-secondary text-white px-6 py-2">
                  <strong><Trans>Contattaci</Trans></strong>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a aria-label="about" href="#" name="about"/>
      <div
        className="flex flex-col md:flex-row justify-center items-center md:items-start md:justify-start overflow-hidden xs:py-16 sm:py-20 md:pb-14 lg:pb-14 lg:py-28 2xl:pt-48 2xl:pb-14 px-8 md:px-16 2xl:px-48 2xl:max-w-screen-3xl mx-auto">
        <div className="flex flex-col md:justify-start mr-0 md:mr-24 2xl:mr-56">
          <div className="mb-4">
            <Translation>
              {t => (
                <Title title={t('About')}/>
              )}
            </Translation>
          </div>
          <div className="mb-4">
          <span className="text-3xl font-bold">
            <Trans>Flessibilità e qualità</Trans>
          </span>
          </div>
          <div className="text-lg pb-8 max-w-md">
            <p className="mb-2">
              <Trans>La tipologia dei tessuti si presta ai più svariati utilizzi rispettando l'eleganza e le esigenze
                dei
                singoli
                utilizzatori.</Trans>
            </p>
            <p>
              <Trans>La flessibilità dell'azienda permette una reale <strong>collaborazione</strong>, per cui è facile
                realizzare
                prodotti e
                disegni <strong>su misura del cliente</strong>.</Trans>
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-end">
            <div className="relative">
              <GatsbyImage image={imageAbout1.childImageSharp.gatsbyImageData} alt="About" className="z-0"/>
              <GatsbyImage image={imageAbout2.childImageSharp.gatsbyImageData} alt="About"
                           className="hidden md:block z-10 bottom-0 left-56 border-8 border-white"
                           style={{ position: 'absolute' }}/>
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto xs:pb-8 sm:pb-10 lg:pb-14">
        <div className="flex flex-col md:flex-row justify-between mx-auto md:px-20 2xl:px-0 2xl:max-w-screen-2xl">
          <div className="flex flex-col items-center py-8">
            <div className="border-2 border-secondary rounded-full flex items-center justify-center"
                 style={{ height: 135, width: 135 }}>
              <GatsbyImage image={iconHand.childImageSharp.gatsbyImageData} alt="Professionalità ed esperienza"/>
            </div>
            <div className="text-xl font-bold text-center">
              <Trans>
                Professionalità ed
                <br/>
                esperienza
              </Trans>
            </div>
          </div>
          <div className="flex flex-col items-center py-8">
            <div className="border-2 border-secondary rounded-full flex items-center justify-center"
                 style={{ height: 135, width: 135 }}>
              <GatsbyImage image={iconPen.childImageSharp.gatsbyImageData} alt="Professionalità ed esperienza"/>
            </div>
            <div className="text-xl font-bold text-center">
              <Trans>
                Customizzazione<br/>
                del prodotto
              </Trans>
            </div>
          </div>
          <div className="flex flex-col items-center py-8">
            <div className="border-2 border-secondary rounded-full flex items-center justify-center"
                 style={{ height: 135, width: 135 }}>
              <GatsbyImage image={iconLeaf.childImageSharp.gatsbyImageData} alt="Professionalità ed esperienza"/>
            </div>
            <div className="text-xl font-bold text-center">
              <Trans>
                Attenzione<br/>
                all'ambiente
              </Trans>
            </div>
          </div>
          <div className="flex flex-col items-center py-8">
            <div className="border-2 border-secondary rounded-full flex items-center justify-center"
                 style={{ height: 135, width: 135 }}>
              <GatsbyImage image={iconGear.childImageSharp.gatsbyImageData} alt="Professionalità ed esperienza"/>
            </div>
            <div className="text-xl font-bold text-center">
              <Trans>
                Ricerca ed<br/>
                innovazione
              </Trans>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a aria-label="tessuti" href="#" name="tessuti"/>
      <div className="bg-primary text-white xs:pt-16 sm:pt-20 lg:pt-28 xs:pb-8 sm:pb-10 lg:pb-14">
        <div className="text-center mb-4">
          <Translation>
            {t => (
              <Title title={t('I nostri tessuti')} color="white"/>
            )}
          </Translation>
        </div>
        <div className="text-center mb-4 px-4">
          <span className="text-3xl font-bold">
          <Trans>
            Tessuti classici e moderni
          </Trans>
          </span>
        </div>
        <div className="text-center xs:pb-8 sm:pb-10 lg:pb-14">
          <p className="text-lg text-center m-auto px-4">
            <Trans>Realizziamo anche prodotti ignifughi.</Trans>
          </p>
        </div>
        <div>
          <Swiper
            centeredSlides={true}
            breakpoints={{
              140: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              756: {
                slidesPerView: 2,
              },
              1176: {
                slidesPerView: 3,
              },
              1500: {
                slidesPerView: 4,
              },
            }}
            freeMode={false}
            autoplay={{
              delay: 10000,
              disableOnInteraction: true
            }}
            loop={true}
            spaceBetween={200}
            modules={[FreeMode, Pagination]}
          >
            {imagesTessuti.nodes.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Tessuto"/>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a aria-label="produzione" href="#" name="produzione"/>
      <div className="xs:pt-16 sm:pt-20 lg:pt-28 xs:pb-8 sm:pb-10 lg:pb-14">
        <div className="text-center mb-4">
          <Translation>
            {t => (
              <Title title={t('Produzione')}/>
            )}
          </Translation>
        </div>
        <div className="text-center mb-4">
          <span className="text-3xl font-bold">
          <Trans>Processi verificati</Trans>
          </span>
        </div>
        <div className="text-center xs:pb-8 sm:pb-10 lg:pb-14">
          <p className="text-lg text-center m-auto max-w-md px-4">
            <Trans>Il processo produttivo è seguito e verificato per
              garantire un'ottima qualità.</Trans>
          </p>
        </div>
        <div>
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[
              Pagination
            ]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true
            }}
            loop={true}
            onSlideChange={() => 0}
            onSwiper={() => 0}
          >
            {[imgProduzione1, imgProduzione2, imgProduzione3, imgProduzione4].map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div>
                    <div className="bg-primary absolute w-full h-full absolute z-20 opacity-40"/>
                    <div className="z-10">
                      <div className="absolute z-20  bottom-0 text-white xs:py-8 md:py-16 px-8 2xl:py-32 md:px-16 2xl:px-48 sm:text-lg md:text-2xl xl:text-3xl font-bold max-w-screen-md 2xl:max-w-screen-xl">
                        <span className="hidden md:block">{tex.n[index + 1]()}</span>
                        <span className="xs:block md:hidden">{tex.xs[index + 1]()}</span>
                      </div>
                      <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Produzione" className="m-auto"/>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a aria-label="contatti" href="#" name="contatti"/>
      <div className="bg-primary text-white xs:py-16 sm:py-20 lg:py-28">
        <div className="text-center mb-4">
          <Translation>
            {t => (
              <Title title={t('Contatti')} color="white"/>
            )}
          </Translation>
        </div>
        <div className="text-center mb-4">
          <span className="text-3xl font-bold">
          <Trans>Dove siamo</Trans>
          </span>
        </div>
        <div className="text-center">
          <p className="text-md text-center m-auto px-4">
            Via Pratobevera, 12 <br/>
            23884 Castello di Brianza (LC) - <Trans>Italia</Trans>
          </p>
        </div>
        <div className="text-center xs:pb-8 sm:pb-10 lg:pb-14">
          <p className="text-lg font-bold text-center m-auto">
            info@texfumagalli.it
            <br/>
            +39 039570454
          </p>
        </div>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe title="Map" width="100%" height="428" id="gmap_canvas"
  src="https://maps.google.com/maps?q=Via%20Pratobevera,%2012%20Castello%20di%20Brianza%20(LC)&t=&z=17&ie=UTF8&iwloc=&output=embed"
  frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
          </div>
        </div>
        <div className="mt-8">
          <p className="text-center text-xs">
            Tessitura Fumagalli Carlo & Figli Srl
            <br/>
            Cod. Fisc. / P.IVA 00208970137
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        imageCover: file(relativePath: { eq: "images/Cover-1.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
        iconPen: file(relativePath: { eq: "icons/Pen.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 75)
            }
        }
        iconLeaf: file(relativePath: { eq: "icons/Leaf.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 75)
            }
        }
        iconHand: file(relativePath: { eq: "icons/Hand.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 85)
            }
        }
        iconGear: file(relativePath: { eq: "icons/Gear.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 75)
            }
        }
        imageAbout1: file(relativePath: { eq: "images/About-1.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
        imageAbout2: file(relativePath: { eq: "images/About-2.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
        imagesProduzione: allFile(filter: {relativePath: {regex: "images/Produzione/g"}}) {
            nodes {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        imgProduzione1: file(relativePath: {eq: "images/Produzione-1.png"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        imgProduzione2: file(relativePath: {eq: "images/Produzione-2.png"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
        }
        imgProduzione3: file(relativePath: {eq: "images/Produzione-3.png"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
        }
        imgProduzione4: file(relativePath: {eq: "images/Produzione-4.png"}) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
        }
        imagesTessuti: allFile(filter: {relativePath: {regex: "images/Tessuti/g"}}) {
            nodes {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, height: 400)
                }
            }
        }
    }
`
