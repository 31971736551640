import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useI18next } from 'gatsby-plugin-react-i18next'
import ReactCountryFlag from 'react-country-flag'

const LanguageIcon = ({ language }) => {
  switch (String(language).toLowerCase()) {
    case 'it':
      return <ReactCountryFlag
        countryCode="IT"
        svg
        style={{
          width: '2em',
          height: '2em',
        }}
      />
    case 'en':
      return <ReactCountryFlag
        countryCode="GB"
        svg
        style={{
          width: '2em',
          height: '2em',
        }}
      />
    default:
      return (<>{String(language).toUpperCase()}</>)
  }
}

export default function LanguageSwitcher () {
  const { languages, changeLanguage, language } = useI18next()

  console.log({ language })

  return (
    <div className="text-primary">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium bg-black rounded-md"
          >
            <span className="w-5 text-secondary font-bold">{language.toUpperCase()}</span>
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="bg-white shadow-md right-7 border-secondary border absolute mt-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
            <div className="px-1 py-1 ">
              {languages.map((lng) => (
                <Menu.Item
                  key={lng}
                  onClick={(e) => {
                    e.preventDefault()
                    changeLanguage(lng)
                  }}
                >
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-violet-500' : 'text-gray-900'
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <LanguageIcon language={lng}/>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
