import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Header from './Header'
import Footer from './Footer'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Layout = (props) => {
  const {t} = useTranslation();
  const { site: { siteMetadata: { siteUrl } } } = useStaticQuery(graphql`query {
      site {
          siteMetadata {
              title
              siteUrl
              description
          }
      }
  }
  `)

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{t('Tessuti Jacquard • Tessitura Fumagalli Carlo & Figli')}</title>
        <link rel="canonical" href={siteUrl}/>
        <meta name="description" content={t('La Tessitura Fumagalli Carlo & Figli è un\'azienda tessile nata nel 1963 con sede a Castello di Brianza (LC), specializzata in tessuti Jacquard.')}/>
      </Helmet>
      <Header/>
      <section>
        {props.children}
      </section>
      <Footer/>
    </main>
  )
}

export default Layout
