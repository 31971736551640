// noinspection HtmlUnknownAnchorTarget

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import LanguageSwitcher from '../shared/LanguageSwitcher'

const Header = () => {
  const { file: logo } = useStaticQuery(graphql`query {
      file(relativePath: { eq: "Logo.png" }) {
          childImageSharp {
              gatsbyImageData(layout: FIXED, width: 200)
          }
      }
  }
  `)

  return (
    <header className="h-24 flex w-full items-center justify-between bg-white md:px-16 2xl:px-0 2xl:max-w-screen-2xl mx-auto">
      <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="Tex Fumagalli" className="xs:ml-8 sm:ml-0"/>
      <div className="flex flex-row">
        <div className="hidden md:flex justify-center">
          <ul className="flex font-bold uppercase">
            <li className="mr-6 hidden lg:flex items-center">
              <a href="#about"><Trans>About</Trans></a>
            </li>
            <li className="mr-6 flex items-center">
              <a href="#tessuti"><Trans>I nostri tessuti</Trans></a>
            </li>
            <li className="mr-6 flex items-center">
              <a href="#produzione"><Trans>Produzione</Trans></a>
            </li>
            <li className="mr-6 flex items-center">
              <a href="#contatti"><Trans>Contatti</Trans></a>
            </li>
          </ul>
        </div>
        <div>
          <LanguageSwitcher/>
        </div>
      </div>
    </header>
  )
}

export default Header
